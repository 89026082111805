.follow-count-container {
    margin: 10px 0 10px 25px;
}

.follow-count-text {
    margin: 0;
    float: left;
}

.follow-count-margin {
    padding-left: 10px;
}

.follow-count-hover:hover {
    cursor: pointer;
    text-decoration: underline;
}