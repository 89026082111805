.profile-header-text {
    margin: 3px 0px;
}

.profile-header-bold {
    font-size: 22px;
    font-weight: bold;
}

.profile-header-small {
    font-size: 16px;
}

.profile-header-icon {
    margin-left: 10px;
}

.profile-header-text-div {
    margin-left: 30px;
}

.profile-header-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}