.selected-tab {
    font-weight: bold !important;
    color: black !important;
    text-decoration: underline solid !important;
    text-decoration-color: #49a7f4 !important;
    text-decoration-thickness: 5px !important;
    text-underline-offset: 15px !important;
}

.unselected-tab:hover {
    background-color: #d8e3ec;
}

.unselected-tab {
    font-weight: bold !important;
}