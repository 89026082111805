.modal {
    min-width: 40vw;
    min-height: 40vh;
    max-height: 80vh;
    white-space: nowrap;
    position: fixed;
    overflow: auto;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.dark-bg {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.centered {
    position: fixed;
    z-index: 10;
    top: 10%;
    left: 30%;
    transform: translate(-50%, -50%);
}

.close-icon {}

.close-icon-border {
    color: black;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
}

.close-icon-border:hover {
    cursor: pointer;
    background-color: #d8e3ec;
    border-radius: 50%;
}