.post-border {
    border: 1px solid rgb(234, 232, 232);
    padding: 10px;
    flex-grow: 1;
}

.profile-picture {
    clip-path: circle();
    margin-top: 15px;
}

/* .clickable {
    border: 1px solid red;

} */

.clickable {
    cursor: pointer;
    border-bottom: 20px solid transparent;
    /* Prevent layout shift */
    transition: border-bottom 0.3s ease;
}

.clickable:hover {
    border-bottom: 1px solid blue;
    /* Or any highlight color */
}

.username {
    font-weight: bold;
}

.text {
    /* margin: 0;
    padding: 0; */
    float: left;
}

.icon-more {
    margin-top: -5px;
    float: right;
    align-items: flex-end;
    cursor: pointer;
}

.username {
    font-weight: bold;
}

.padding {
    padding: 5px;
}

.request {
    clear: both;
}

.content-text {
    font-size: larger;
}

.footer-text {
    font-size: 6px;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}