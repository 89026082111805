.profile-pic {
    clip-path: circle();
    margin-left: 20px;
    margin-top: -75px;
    background-color: lightgray;
    border: 5px solid white;
    border-radius: 50%;
    padding: 5px;
}

.edit-profile-button {
    border-color: #d1d4d7 !important;
    font-weight: bold !important;
    font-size: medium !important;
    border-radius: 20px !important;
    text-transform: none !important;
    float: right;
    margin-right: 20px !important;
    margin-top: -60px !important;
}

/* .edit-profile-button:hover {
    background-color: #d1d4d7 !important;
    border-color: black;
} */

.profile-text {
    margin: 0px 0 5px 25px;
    padding: 0;
}

.profile-bold {
    font-weight: bold;
}

.profile-name {
    margin-top: 10px;
    font-size: 20px;
}

.profile-date-joined {
    margin-top: 10px;
}