.button {
    border-radius: 20px;
    white-space: normal;
}

.button:hover {
    background-color: #d8e3ec;
}

.chat-button {
    background-color: #49a7f4;
    color: white;
    font-size: 20px
}

.chat-button:hover {
    background-color: #49a7f4;
}

.chat-button:hover icon:not(:hover) {
    background-color: #49a7f4;
    color: white;
}

.nav-button {}

.unselected-button {}

.selected-button {
    font-weight: 800;
}

.chat-button {
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
}


.bold {
    font-weight: bold;
}

.text-size-nav {
    font-size: 20px;
}

.list-icon {
    color: black;
}

.list {
    /* width: 100%; */
    margin-left: 75px;
}

.twitter-icon {
    color: #49a7f4;
}

.new-chat-button {
    text-align: center;
}

.list-button {}